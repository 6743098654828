import React from "react";

interface ProgressBarProps {
  current: number;
  goal: number;
  timeProgress: number;
  goalProgress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ current, goal, timeProgress, goalProgress }) => {
  const percentage = (current / goal) * 100;
  const barWidth = Math.min(percentage, 100);

  const getProgressColor = (goalProgress: number, timeProgress: number) => {
    const progressDifference = goalProgress - timeProgress;
    if (progressDifference < -5) {
      return "bg-red-500";
    } else if (progressDifference > 5) {
      return "bg-green-500";
    } else {
      return "bg-yellow-500";
    }
  };

  const getTextColor = (percent: number) => {
    return percent < 50 ? "text-white" : "text-black";
  };

  const colorClass = getProgressColor(goalProgress, timeProgress);

  return (
    <div className="w-full space-y-1">
      <div className="relative w-full h-6 rounded">
        <div className={`h-full ${colorClass} rounded transition-all duration-500 ease-in-out`} style={{ width: `${barWidth}%` }} />
        <div className="absolute inset-0 flex items-center justify-center">
          <span className={`text-xs ${getTextColor(barWidth)}`}>{percentage.toFixed(1)}%</span>
        </div>
      </div>

      <div className="flex justify-between text-xs">
        <span>{current.toFixed(0)} (ist)</span>
        <span>{goal.toFixed(0)} (soll)</span>
      </div>
    </div>
  );
};

export default ProgressBar;
