import { ArrowsPointingInIcon, CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SidebarHeader from "./SidebarHeader";
import SidebarSettingsControl from "./SidebarSettingsControl";
import SidebarUserControl from "./SidebarUserControl";

const Sidebar: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const navItems = [
    { name: "Ziele", path: "/goals", icon: ArrowsPointingInIcon },
    { name: "Meine Aufgaben", path: "/my-tasks", icon: CheckCircleIcon },
  ];

  return (
    <div className={`relative flex-shrink-0 transition-all duration-300 ease-in-out`} style={{ width: isCollapsed ? "0" : "256px" }}>
      {/* Sidebar Content */}
      <div
        className={`
          absolute left-0 top-0 flex flex-col h-screen bg-base-100 text-base-content 
          border-e-2 border-neutral transition-transform duration-300 ease-in-out
          ${isCollapsed ? "-translate-x-full" : "translate-x-0"}
        `}
        style={{ width: "256px" }}
      >
        {/* Toggle Button - Positioned differently based on state */}
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className={`
            absolute top-20 bg-base-100 border-2 border-neutral rounded-full p-1 hover:bg-neutral z-50
            ${isCollapsed ? "right-0 translate-x-full" : "-right-3"}
          `}
        >
          {isCollapsed ? <ChevronRightIcon className="w-4 h-4" /> : <ChevronLeftIcon className="w-4 h-4" />}
        </button>

        <SidebarHeader />
        <nav className="flex-1 mx-4 mt-10">
          <ul>
            {navItems.map((item) => (
              <li key={item.name}>
                <Link to={item.path} className="flex gap-2 items-center py-2 px-4 hover:bg-neutral rounded text-sm">
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <SidebarSettingsControl />
        <SidebarUserControl />
      </div>
    </div>
  );
};

export default Sidebar;
