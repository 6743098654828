import { BuildingOfficeIcon, CalendarIcon, FlagIcon, PuzzlePieceIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { StudioCancellationMetricsProjection, StudioMetricsProjection } from "../backend-types";
import TaskTable from "../components/TaskTable";
import { toastStore } from "../components/ToastContainer";
import { handleError } from "../errorHandler";
import { MetricType } from "../stores/GoalStore";
import { rootStore } from "../stores/RootStore";

type MetricsProjection = StudioMetricsProjection | StudioCancellationMetricsProjection;

const GoalDetailsPage = observer(() => {
  const { id } = useParams();
  const { goalStore, taskStore, userStore, authStore, customerStore, groupStore } = rootStore;
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      userStore.refreshUsers();
      goalStore.refreshGoals();
      taskStore.loadTasksForGoal(id);
    }
  }, [id, goalStore, taskStore, userStore]);

  useEffect(() => {
    if (isAddingTask && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingTask]);

  const handleAddTask = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && newTaskTitle.trim() && id) {
      try {
        await taskStore.createTask(id, newTaskTitle, authStore.user!.id || "");
        setNewTaskTitle("");
        setIsAddingTask(false);
      } catch (error) {
        const { type, message } = handleError(error, "Aufgabe erstellen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to create task:", error);
      }
    } else if (e.key === "Escape") {
      setNewTaskTitle("");
      setIsAddingTask(false);
    }
  };

  const handleBlur = () => {
    if (!newTaskTitle.trim()) {
      setIsAddingTask(false);
    }
  };

  const handleToggleTask = async (taskId: string) => {
    if (id) {
      try {
        await taskStore.toggleTaskCompletion(id, taskId);
      } catch (error) {
        const { type, message } = handleError(error, "Aufgabenstatus ändern");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to toggle task:", error);
      }
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    if (id) {
      try {
        await taskStore.deleteTask(id, taskId);
      } catch (error) {
        const { type, message } = handleError(error, "Aufgabe löschen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to delete task:", error);
      }
    }
  };

  const getLocationName = (goal: any) => {
    if (goal.studioId) {
      const studio = customerStore.studios.find((s) => s.id === goal.studioId);
      return `Studio: ${studio?.name || "Unbekannt"}`;
    }
    if (goal.groupId) {
      const group = groupStore.groups.find((g) => g.id === goal.groupId);
      return `Gruppe: ${group?.groupName || "Unbekannt"}`;
    }
    return "Keine Zuordnung";
  };

  const goal = goalStore.goals.find((g) => g.id === id);
  const metricsState = goal ? goalStore.getMetricsState(goal.id) : null;
  const tasks = id ? taskStore.getTasksForGoal(id) : [];
  const isLoadingTasks = id ? taskStore.isLoadingForGoal(id) : false;

  if (!goal || !metricsState || metricsState.isLoading || isLoadingTasks) {
    return (
      <div className="flex h-full w-full justify-center items-center">
        <div className="loading loading-spinner loading-lg"></div>
      </div>
    );
  }

  const metrics = metricsState.rawData || ([] as MetricsProjection[]);
  const currentValue = (() => {
    const lastValidMetric = [...metrics].reverse().find((metric) => metric.runningTotal > 0);
    return lastValidMetric?.runningTotal || 0;
  })();

  // Calculate time progress with proper date handling
  const startDate = goal.startDate ? DateTime.fromJSDate(new Date(goal.startDate)) : DateTime.fromJSDate(metrics[0]?.date || new Date());
  const endDate = DateTime.fromJSDate(new Date(goal.endDate));
  const now = DateTime.now();

  const totalDays = Math.max(endDate.diff(startDate, "days").days, 1);
  const elapsedDays = Math.max(Math.min(now.diff(startDate, "days").days, totalDays), 0);
  const timeProgress = (elapsedDays / totalDays) * 100;
  const differenceToTarget = goal.targetValue - currentValue;
  const isGoalReached = currentValue >= goal.targetValue;

  // Calculate daily averages based on metric type
  const currentDaily = (() => {
    if (metrics.length === 0) return 0;

    switch (goal.metricType) {
      case MetricType.NEW_MEMBERS:
        return (metrics as StudioMetricsProjection[]).reduce((sum, metric) => sum + metric.averageDailyCount, 0) / metrics.length;

      case MetricType.CANCELLATIONS:
        return (metrics as StudioCancellationMetricsProjection[]).reduce((sum, metric) => sum + metric.dailyCount, 0) / metrics.length;

      default:
        return 0;
    }
  })();

  // Calculate goal progress based on metric type
  const goalProgress = (() => {
    switch (goal.metricType) {
      case MetricType.CANCELLATIONS:
        return currentValue <= goal.targetValue ? 100 : Math.max(0, (goal.targetValue / currentValue) * 100);

      default:
        return (currentValue / goal.targetValue) * 100;
    }
  })();

  const remainingDays = Math.max(totalDays - elapsedDays, 1);
  const requiredDaily = (() => {
    if (elapsedDays >= totalDays || isGoalReached) {
      return 0;
    }
    const remaining = goal.targetValue - currentValue;
    const daily = remaining / remainingDays;

    switch (goal.metricType) {
      case MetricType.CANCELLATIONS:
        return currentValue >= goal.targetValue ? 0 : Number(daily.toFixed(2));
      default:
        return Number(daily.toFixed(2));
    }
  })();

  // Calculate projection based on metric type
  const projectedValue = (() => {
    if (elapsedDays >= totalDays) {
      return currentValue;
    }

    // Wenn currentDaily Infinity oder NaN ist, berechne Gesamtdurchschnitt
    const validDaily = isFinite(currentDaily) ? currentDaily :
      metrics
        .filter(metric => metric.dailyCount > 0)
        .reduce((sum, metric) => sum + metric.dailyCount, 0) / elapsedDays;

    const calculated = currentValue + validDaily * remainingDays;
    return isNaN(calculated) ? currentValue : calculated;
  })();

  const formatDate = (date: Date | string) => {
    return DateTime.fromJSDate(new Date(date)).toLocaleString({
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <div className="flex h-full w-full justify-center">
      <div className="flex flex-col mt-4 gap-4 w-10/12 h-full">
        {/* Header */}
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">{goal.description}</h1>
            <div className="flex items-center gap-4 text-sm">
              {/* Kennzahl Badge */}
              <div className="flex items-center gap-2 opacity-70">
                <PuzzlePieceIcon className="size-4" />
                <span>{goalStore.getMetricTypeLabel(goal.metricType as MetricType)}</span>
              </div>
              {/* Date Range */}
              <div className="flex items-center gap-2 opacity-70">
                <CalendarIcon className="size-4" />
                <span>
                  {formatDate(goal.startDate)} - {formatDate(goal.endDate)}
                </span>
              </div>

              {/* Target Value */}
              <div className="flex items-center gap-2 opacity-70">
                <FlagIcon className="size-4" />
                <span>Zielwert: {goal.targetValue.toLocaleString()}</span>
              </div>

              {/* Studio/Group Name */}
              <div className="flex items-center gap-2 opacity-70">
                <BuildingOfficeIcon className="size-4" />
                <span>{getLocationName(goal)}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Metrics Cards */}
        <div className="grid grid-cols-3 gap-4">
          {/* Progress Chart */}
          <div className="border border-neutral rounded p-4 col-span-2">
            <div className="font-bold mb-4">Fortschrittsverlauf</div>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={metrics}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" opacity={0.2} />
                  <XAxis
                    dataKey="date"
                    tickFormatter={(date) => DateTime.fromJSDate(new Date(date)).toFormat("MM.yy")}
                    tick={{ fontSize: 11 }}
                    interval="preserveStartEnd"
                    minTickGap={40} // Minimaler Abstand zwischen Ticks
                  />
                  <YAxis tick={{ fontSize: 11 }} domain={[0, "auto"]} />
                  <Tooltip
                    formatter={(value) => [value, "Wert"]}
                    labelFormatter={(date) => DateTime.fromJSDate(new Date(date)).toFormat("dd.MM.yyyy")}
                    contentStyle={{
                      backgroundColor: "#161616",
                      border: "1px solid #374151",
                      borderRadius: "0.375rem",
                      fontSize: "11px",
                      padding: "0.5rem",
                      color: "white",
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="runningTotal"
                    stroke="#2563eb"
                    dot={false}
                    activeDot={{
                      fill: "#2563eb",
                      stroke: "#white",
                      r: 6,
                      strokeWidth: 2,
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Progress vs Time - Reorganized */}
          <div className="border border-neutral rounded p-4">
            <div className="font-bold mb-4">Fortschritt vs. Zeit</div>
            <div className="space-y-4">
              {/* Zeitfortschritt */}
              <div>
                <div className="flex justify-between mb-1">
                  <span>Zeitfortschritt</span>
                  <span>{timeProgress.toFixed(1)}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div className="bg-primary h-2 rounded-full" style={{ width: `${timeProgress}%` }} />
                </div>
              </div>

              {/* Zielfortschritt */}
              <div>
                <div className="flex justify-between mb-1">
                  <span>Zielfortschritt</span>
                  <span>{goalProgress.toFixed(1)}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div className="bg-primary h-2 rounded-full" style={{ width: `${Math.min(goalProgress, 100)}%` }} />
                </div>
              </div>

              <div className="pt-4 border-t border-neutral space-y-4">
                {/* Prognose */}
                <div>
                  <p className="text-sm font-medium">Prognose zum {formatDate(goal.endDate)}</p>
                  {elapsedDays >= totalDays ? <p className="text-sm opacity-70">Deadline erreicht</p> : <p className="text-xl font-bold">{projectedValue.toFixed(1) || "wird ermittelt"}</p>}
                </div>

                {/* Differenz zum Zielwert */}
                <div>
                  <p className="text-sm font-medium">Differenz zum Zielwert</p>
                  {isGoalReached ? <p className="text-sm opacity-70">Ziel erreicht</p> : <p className="text-xl font-bold">{differenceToTarget.toFixed(0)}</p>}
                </div>

                {/* Tagesdurchschnitt Soll */}
                <div>
                  <p className="text-sm font-medium">Tagesdurchschnitt Soll</p>
                  {elapsedDays >= totalDays ? (
                    <p className="text-sm opacity-70">Deadline erreicht</p>
                  ) : isGoalReached ? (
                    <p className="text-sm opacity-70">Ziel erreicht</p>
                  ) : (
                    <p className="text-xl font-bold">{requiredDaily.toFixed(1)}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TASK TABLE */}
        <div className="flex-1 min-h-0 mb-4">
          <TaskTable
            tasks={tasks}
            onToggleTask={handleToggleTask}
            getUserName={(userId: string) => userStore.getUserName(userId)}
            showAddButton={true}
            onEditTask={(taskId: string) => navigate(`/goals/task/edit/${goal.id}/${taskId}`)}
            onDeleteTask={handleDeleteTask}
            getGoalName={() => goal.description}
            goalId={goal.id}
            addTaskComponent={
              isAddingTask ? (
                <div className="flex items-center gap-2">
                  <input type="checkbox" checked={false} disabled className="checkbox checkbox-sm text-neutral-500" />
                  <input
                    ref={inputRef}
                    type="text"
                    value={newTaskTitle}
                    onChange={(e) => setNewTaskTitle(e.target.value)}
                    onKeyDown={handleAddTask}
                    onBlur={handleBlur}
                    placeholder="Neue Aufgabe eingeben... (Enter zum Speichern)"
                    className="w-full bg-transparent border-none focus:outline-none"
                  />
                </div>
              ) : undefined
            }
            onAddTask={() => setIsAddingTask(true)}
          />
        </div>
      </div>
    </div>
  );
});

export default GoalDetailsPage;
