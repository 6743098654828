import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EmptyState from "../components/EmptyState";
import TableMenu, { MenuAction } from "../components/MenuAction";
import RefreshButton from "../components/RefreshButton";
import { toastStore } from "../components/ToastContainer";
import { rootStore } from "../stores/RootStore";
import { useConfirmationModal } from "../components/ConfirmationModal";
import { UserInformationDTO } from "../backend-types";
import Badge from "../components/Badge";
import SearchBar from "../components/SearchBar";
import { handleError } from "../errorHandler";

const UsersPage: React.FC = observer(() => {
  const { userStore } = rootStore;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("user") || "");
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();

  useEffect(() => {
    userStore.refreshUsers();
  }, [userStore]);

  const handleRefreshUsers = async () => {
    try {
      await userStore.refreshUsers();
    } catch (error: any) {
      const { type, message } = handleError(error, "Nutzer abrufen");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to refresh users:", error);
    }
  };

  const handleDeleteUser = async (user: UserInformationDTO) => {
    const confirmed = await showConfirmationModal({
      title: "Nutzer löschen",
      text: `Möchten Sie den Nutzer "${user.firstname} ${user.lastname}" wirklich löschen?`,
      confirmText: "Löschen",
      cancelText: "Abbrechen",
    });

    if (confirmed) {
      try {
        await userStore.deleteUser(user.id);
        toastStore.add({
          type: "success",
          caption: "Erfolg",
          message: "Nutzer wurde erfolgreich gelöscht",
        });
      } catch (error: any) {
        const { type, message } = handleError(error, "Nutzer löschen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to delete user:", error);
      }
    }
  };

  const getMenuActions = (user: UserInformationDTO): MenuAction[] => [
    {
      label: "Bearbeiten",
      icon: <PencilIcon className="size-4" />,
      onClick: () => navigate(`/members/edit/${user.id}`),
    },
    {
      label: "Löschen",
      icon: <TrashIcon className="size-4" />,
      onClick: () => handleDeleteUser(user),
      variant: "error",
    },
  ];

  const filteredUsers = useMemo(() => {
    if (!searchTerm.trim()) return userStore.users;

    const searchLower = searchTerm.toLowerCase().trim();
    return userStore.users.filter((user) => {
      return (
        user.firstname.toLowerCase().includes(searchLower) ||
        user.lastname.toLowerCase().includes(searchLower) ||
        user.email.toLowerCase().includes(searchLower) ||
        user.roles.toString().toLowerCase().includes(searchLower)
      );
    });
  }, [userStore.users, searchTerm]);

  const renderUserRow = (user: UserInformationDTO) => (
    <tr key={user.id} className="border-b border-neutral last:border-b-0">
      <td>
        <div className="flex-grow min-w-0 mr-2">
          <div className="text-sm font-bold truncate">
            {user.firstname} {user.lastname}
          </div>
          <div className="text-xs text-neutral-500 truncate">{user.email}</div>
        </div>
      </td>
      <td>
        <div className="flex gap-2">
          {user.roles.map((role: any) => (
            <Badge key={role} text={role} />
          ))}
        </div>
      </td>
      <td>
        <TableMenu actions={getMenuActions(user)} />
      </td>
    </tr>
  );

  return (
    <div className="w-full h-fit flex justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-7/12">
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <span className="text-2xl font-bold">Nutzer</span>
            <RefreshButton onClick={handleRefreshUsers} showLabel={false} />
          </div>
          <button className="btn btn-primary btn-s" onClick={() => navigate("/members/create")}>
            <p>Nutzer hinzufügen</p>
          </button>
        </div>
        <div className="flex gap-2 w-fit">
          <SearchBar onSearch={setSearchTerm} placeholder="Nutzer suchen" paramName="user" />
        </div>
        <div className="border border-neutral rounded p-4">
          {filteredUsers.length ? (
            <div>
              {userStore.isLoading ? (
                <div className="text-center">Loading...</div>
              ) : (
                <div className="max-h-[calc(100vh-300px)] overflow-y-auto h-fit">
                  <table className="table w-full">
                    <colgroup>
                      <col className="w-[45%]" />
                      <col className="w-[45%]" />
                      <col className="w-[10%]" />
                    </colgroup>
                    <thead className="sticky top-0 bg-base-100 opacity-100">
                      <tr className="border-b border-neutral">
                        <th className="text-left">Name</th>
                        <th className="text-left">Rollen</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{filteredUsers.map(renderUserRow)}</tbody>
                  </table>
                </div>
              )}
            </div>
          ) : (
            <EmptyState message={searchTerm ? "Keine Nutzer gefunden!" : "Keine Nutzer verfügbar!"} onRefresh={handleRefreshUsers} />
          )}
        </div>
      </div>
    </div>
  );
});

export default UsersPage;
