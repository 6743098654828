import React from "react";

interface BadgeProps {
  text: string;
}

const StatusBadge: React.FC<BadgeProps> = ({ text }) => {
  return (
    <div className="badge badge-md border-neutral rounded gap-2 max-w-full">
      <span className="truncate" title={text}>
        {text}
      </span>
    </div>
  );
};

export default StatusBadge;
