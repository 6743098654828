import { observer } from "mobx-react-lite";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const AppLayout: React.FC = observer(() => {
  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <main className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-1 overflow-auto px-4 pb-4">
          <Outlet />
        </div>
      </main>
    </div>
  );
});

export default AppLayout;
