import { makeAutoObservable, runInAction } from "mobx";
import { apiService } from "../ApiService";
import { RootStore } from "./RootStore";
import { TaskDTO } from "../backend-types";
import { API_CONFIG } from "../config/api.config";

export class TaskStore {
  public tasksByGoal: Map<string, TaskDTO[]> = new Map();
  private loadingGoals: Set<string> = new Set();
  error: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  isLoadingForGoal(goalId: string): boolean {
    return this.loadingGoals.has(goalId);
  }

  getTasksForGoal(goalId: string): TaskDTO[] {
    return this.tasksByGoal.get(goalId) || [];
  }

  async loadTasksForGoal(goalId: string) {
    runInAction(() => {
      this.loadingGoals.add(goalId);
      this.error = null;
    });

    try {
      const response = await apiService.get<TaskDTO[]>(`${API_CONFIG.ENDPOINTS.TASKS_GET}/${goalId}`);

      runInAction(() => {
        this.tasksByGoal.set(goalId, response);
        this.loadingGoals.delete(goalId);
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden der Aufgaben";
        this.loadingGoals.delete(goalId);
      });
      throw error;
    }
  }

  async createTask(goalId: string, title: string, ownerId: string) {
    try {
      const taskDTO: TaskDTO = {
        id: "",
        title,
        completed: false,
        ownerId,
        goalId,
      };

      const response = await apiService.post<TaskDTO>(`${API_CONFIG.ENDPOINTS.TASKS_CREATE}/${goalId}`, taskDTO);

      runInAction(() => {
        const currentTasks = this.tasksByGoal.get(goalId) || [];
        this.tasksByGoal.set(goalId, [...currentTasks, response]);
        this.error = null;
      });

      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Erstellen der Aufgabe";
      });
      throw error;
    }
  }

  async updateTask(goalId: string, taskId: string, updates: Partial<TaskDTO>) {
    try {
      const response = await apiService.put<TaskDTO>(`${API_CONFIG.ENDPOINTS.TASKS_UPDATE}/${taskId}/goal/${goalId}`, updates);

      runInAction(() => {
        const tasks = this.tasksByGoal.get(goalId) || [];
        const updatedTasks = tasks.map((task) => (task.id === taskId ? response : task));
        this.tasksByGoal.set(goalId, updatedTasks);
        this.error = null;
      });

      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Aktualisieren der Aufgabe";
      });
      throw error;
    }
  }

  async deleteTask(goalId: string, taskId: string) {
    try {
      await apiService.delete(`${API_CONFIG.ENDPOINTS.TASKS_DELETE}/${taskId}/goal/${goalId}`);

      runInAction(() => {
        const tasks = this.tasksByGoal.get(goalId) || [];
        this.tasksByGoal.set(
          goalId,
          tasks.filter((task) => task.id !== taskId)
        );
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Löschen der Aufgabe";
      });
      throw error;
    }
  }

  async toggleTaskCompletion(goalId: string, taskId: string) {
    try {
      const response = await apiService.patch<TaskDTO>(`${API_CONFIG.ENDPOINTS.TASKS_TOGGLE}/${taskId}/goal/${goalId}/toggle`);

      runInAction(() => {
        const newTasksByGoal = new Map(this.tasksByGoal);
        const tasks = newTasksByGoal.get(goalId) || [];
        const updatedTasks = tasks.map((task) => (task.id === taskId ? response : task));
        newTasksByGoal.set(goalId, updatedTasks);
        this.tasksByGoal = newTasksByGoal;
        this.error = null;
      });

      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Ändern des Aufgabenstatus";
      });
      throw error;
    }
  }

  async loadTasksForUser(userId: string) {
    try {
      const response = await apiService.get<TaskDTO[]>(`${API_CONFIG.ENDPOINTS.TASKS_FOR_USER_GET}/${userId}`);

      runInAction(() => {
        // Group tasks by goal using reduce
        const tasksByGoal = response.reduce((acc, task) => {
          if (!acc.has(task.goalId)) {
            acc.set(task.goalId, []);
          }
          acc.get(task.goalId)!.push(task);
          return acc;
        }, new Map<string, TaskDTO[]>());

        // Update the store with the new grouped tasks
        tasksByGoal.forEach((tasks, goalId) => {
          this.tasksByGoal.set(goalId, tasks);
        });
        this.error = null;
      });

      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden der Aufgaben des Nutzers";
      });
      throw error;
    }
  }

  clearError() {
    this.error = null;
  }

  resetStore() {
    this.tasksByGoal.clear();
    this.loadingGoals.clear();
    this.error = null;
  }

  getCompletedTasks(goalId: string): TaskDTO[] {
    const tasks = this.tasksByGoal.get(goalId) || [];
    return tasks.filter((task) => task.completed);
  }

  getPendingTasks(goalId: string): TaskDTO[] {
    const tasks = this.tasksByGoal.get(goalId) || [];
    return tasks.filter((task) => !task.completed);
  }

  getTasksForOwner(ownerId: string): TaskDTO[] {
    const allTasks: TaskDTO[] = [];
    this.tasksByGoal.forEach((tasks) => {
      allTasks.push(...tasks.filter((task) => task.ownerId === ownerId));
    });
    return allTasks;
  }

  getCompletionRate(goalId: string): number {
    const tasks = this.tasksByGoal.get(goalId) || [];
    if (tasks.length === 0) return 0;

    const completedTasks = tasks.filter((task) => task.completed).length;
    return (completedTasks / tasks.length) * 100;
  }
}
