import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GroupDTO } from "../backend-types";
import Badge from "../components/Badge";
import EmptyState from "../components/EmptyState";
import TableMenu, { MenuAction } from "../components/MenuAction";
import RefreshButton from "../components/RefreshButton";
import { toastStore } from "../components/ToastContainer";
import { rootStore } from "../stores/RootStore";
import SearchBar from "../components/SearchBar";
import { handleError } from "../errorHandler";
import { useConfirmationModal } from "../components/ConfirmationModal";

const GroupPage: React.FC = observer(() => {
  const { groupStore, customerStore } = rootStore;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("group") || "");
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();

  useEffect(() => {
    customerStore.refreshCustomer();
    groupStore.refreshGroups();
  }, [groupStore, customerStore]);

  const handleRefreshGroups = async () => {
    try {
      await groupStore.refreshGroups();
    } catch (error: any) {
      const { type, message } = handleError(error, "Gruppen abrufen");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to refresh groups:", error);
    }
  };

  const handleDeleteGroup = async (group: GroupDTO) => {
    const confirmed = await showConfirmationModal({
      title: "Gruppe löschen",
      text: `Möchten Sie die Gruppe "${group.groupName}" wirklich löschen?`,
      confirmText: "Löschen",
      cancelText: "Abbrechen",
    });

    if (confirmed) {
      try {
        await groupStore.deleteGroup(group.id);
        toastStore.add({
          type: "success",
          caption: "Erfolg",
          message: "Gruppe wurde erfolgreich gelöscht",
        });
      } catch (error: any) {
        const { type, message } = handleError(error, "Gruppen löschen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to delete group:", error);
      }
    }
  };

  const getMenuActions = (group: GroupDTO): MenuAction[] => [
    {
      label: "Bearbeiten",
      icon: <PencilIcon className="size-4" />,
      onClick: () => navigate(`/groups/edit/${group.id}`),
    },
    {
      label: "Löschen",
      icon: <TrashIcon className="size-4" />,
      onClick: () => handleDeleteGroup(group),
      variant: "error",
    },
  ];

  const filteredGroups = useMemo(() => {
    if (!searchTerm.trim()) return groupStore.groups;

    const searchLower = searchTerm.toLowerCase().trim();

    return groupStore.groups.filter((group) => {
      if (group.groupName.toLowerCase().includes(searchLower)) return true;
      return false;
    });
  }, [groupStore.groups, searchTerm]);

  const renderGroupRow = (group: GroupDTO) => (
    <tr key={group.id} className="border-b border-neutral last:border-b-0">
      {/* NAME and STUDIOS */}
      <td>
        <div className="flex-grow min-w-0 mr-2">
          <div className="text-sm font-bold truncate" title={group.groupName}>
            {group.groupName}
          </div>
          <div className="text-xs text-neutral-500 truncate">{group.studioIds?.length || 0} Studios</div>
        </div>
      </td>
      {/* STUDIOS LIST */}
      <td>
        <div className="flex gap-2 cursor-pointer">
          {group.studioIds.map((id) => {
            const studio = customerStore.studios.find((studio) => studio.id === id);
            return (
              <div key={id} onClick={() => navigate(`/studios?studio=${encodeURIComponent(studio?.name || "")}`, { replace: true })}>
                <Badge text={studio?.name || "NOT_FOUND"} />
              </div>
            );
          })}
        </div>
      </td>
      {/* CONTROLS */}
      <td>
        <TableMenu actions={getMenuActions(group)} />
      </td>
    </tr>
  );

  return (
    <div className="w-full h-fit flex justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-7/12">
        {/* PAGE HEADER */}
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <span className="text-2xl font-bold">Gruppen</span>
            <RefreshButton onClick={() => groupStore.refreshGroups()} showLabel={false} />
          </div>
          <button className="btn btn-primary btn-s" onClick={() => navigate("/groups/create")}>
            <p>Gruppe erstellen</p>
          </button>
        </div>
        {/* FILTER SECTION */}
        <div className="flex gap-2 w-fit">
          <SearchBar onSearch={setSearchTerm} placeholder="Gruppen suchen" paramName="group" />
        </div>
        {/* TABLE */}
        <div className="border border-neutral rounded p-4">
          {filteredGroups.length ? (
            <div>
              {/* TODO: Use loading spinner */}
              {groupStore.isLoading ? (
                <div className="text-center">Loading...</div>
              ) : (
                <div className="max-h-[calc(100vh-300px)] overflow-y-auto h-fit">
                  <table className="table w-full">
                    <colgroup>
                      <col className="w-[30%]" />
                      <col className="w-[60%]" />
                      <col className="w-[10%]" />
                    </colgroup>
                    <thead className="sticky top-0 bg-base-100 opacity-100">
                      <tr className="border-b border-neutral">
                        <th className="text-left">Name</th>
                        <th className="text-left">Studios</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{filteredGroups.map(renderGroupRow)}</tbody>
                  </table>
                </div>
              )}
            </div>
          ) : (
            <EmptyState message={searchTerm ? "Keine Gruppen gefunden!" : "Keine Gruppen verfügbar!"} onRefresh={handleRefreshGroups} />
          )}
        </div>
      </div>
    </div>
  );
});

export default GroupPage;
