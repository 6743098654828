import React, { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { rootStore } from "../stores/RootStore";
import { useConfirmationModal } from "../components/ConfirmationModal";
import { toastStore } from "../components/ToastContainer";
import { UserCreationDTO } from "../backend-types";
import { handleError } from "../errorHandler";

const CreateUserPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { userStore, authStore } = rootStore;
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();
  const [user, setUser] = useState<UserCreationDTO>({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    roleId: "",
  });

  useEffect(() => {
    const loadRoles = async () => {
      await authStore.fetchRoles();
    };
    loadRoles();
  }, [authStore]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await userStore.createUser(user);
      toastStore.add({
        type: "success",
        caption: "Erfolg",
        message: "Nutzer wurde erfolgreich erstellt",
      });
      navigate("/members");
    } catch (error) {
      const { type, message } = handleError(error, "Nutzer erstellen");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to create user:", error);
    }
  };

  const handleNavigation = useCallback(
    async (path: string) => {
      const hasUnsavedChanges = user.email !== "" || user.firstname !== "" || user.lastname !== "" || user.password !== "" || user.roleId !== "";

      if (hasUnsavedChanges) {
        const confirmed = await showConfirmationModal({
          title: "Ungespeicherte Änderungen",
          text: "Sie haben ungespeicherte Änderungen. Möchten Sie die Seite trotzdem verlassen?",
          confirmText: "Verlassen",
          cancelText: "Abbrechen",
        });
        if (confirmed) navigate(path);
      } else {
        navigate(path);
      }
    },
    [user, navigate, showConfirmationModal]
  );

  const availableRoles = authStore.roles.filter((role) => role.roleName === "USER" || role.roleName === "MANAGER");

  return (
    <div className="flex h-full w-full justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-7/12 h-full">
        <span className="text-xl font-bold">Neuen Nutzer erstellen</span>
        <div className="flex flex-col mt-4 px-4 border border-neutral rounded">
          <form onSubmit={handleSubmit}>
            <table className="table table-fixed w-full">
              <colgroup>
                <col className="w-4" />
                <col className="w-4" />
              </colgroup>
              <tbody>
                {/* Email */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>E-Mail</span>
                  </td>
                  <td>
                    <div>
                      <input type="email" name="email" value={user.email} onChange={handleChange} className="input input-sm input-bordered w-full rounded" required />
                    </div>
                  </td>
                </tr>
                {/* First Name */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Vorname</span>
                  </td>
                  <td>
                    <div>
                      <input type="text" name="firstname" value={user.firstname} onChange={handleChange} className="input input-sm input-bordered w-full rounded" required />
                    </div>
                  </td>
                </tr>
                {/* Last Name */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Nachname</span>
                  </td>
                  <td>
                    <div>
                      <input type="text" name="lastname" value={user.lastname} onChange={handleChange} className="input input-sm input-bordered w-full rounded" required />
                    </div>
                  </td>
                </tr>
                {/* Password */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Passwort</span>
                  </td>
                  <td>
                    <div>
                      <input type="password" name="password" value={user.password} onChange={handleChange} className="input input-sm input-bordered w-full rounded" required />
                    </div>
                  </td>
                </tr>
                {/* Role */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Rolle</span>
                  </td>
                  <td>
                    <div>
                      <select name="roleId" value={user.roleId} onChange={handleChange} className="select select-sm select-bordered w-full rounded" required>
                        <option value="">Rolle auswählen</option>
                        {availableRoles.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.roleName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                </tr>
                {/* Controls */}
                <tr>
                  <td colSpan={2}>
                    <div className="flex gap-4 py-4">
                      <button type="button" className="btn btn-neutral" onClick={() => handleNavigation("/members")}>
                        Abbrechen
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={!user.email || !user.firstname || !user.lastname || !user.password || !user.roleId}>
                        Nutzer erstellen
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateUserPage;
